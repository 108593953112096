import { IMAGE_URL_ROOT } from '../util/globals'
export const imageMixin = {
  methods: {
    handleClickRow(value) {
      return {
        on: {
          dblclick: () => {
            this.display = 'none'
            // console.log(value);
            this.thing = value
          },
        },
      }
    },
    imageUrl(imageName) {
      //   console.log(imageName);
      return IMAGE_URL_ROOT + imageName
    },
  },
}
