<template>
  <a-layout style="padding: 0px; height: 100%; overflow: auto">
    <a-layout-content
      style="
        background-color: #fff;
        line-height: 60px;
        margin: 0;
        width: 100%;
        height: 60px;
      "
    >
      <a-modal
        okText="确定"
        @ok="onout"
        :closable="false"
        :maskClosable="false"
        :visible="logvisible"
        :cancel-button-props="{ props: { disabled: true } }"
      >
        登录已失效，请前往重新登陆
      </a-modal>
      <div class="Enter-top">
        <div>
          <a-breadcrumb style="margin: 18px 0">
            <a-breadcrumb-item>资产管理</a-breadcrumb-item>
            <a-breadcrumb-item>铺位管理</a-breadcrumb-item>
          </a-breadcrumb>
        </div>
        <div v-if="visible == ''" class="Enter-top-right">
          <div style="float: right">
            <a-button>
              <router-link @click="tostorechange" :to="{ name: 'storeAdjust' }">
                调整铺位
              </router-link>
            </a-button>
            <a-button @click="outexcel">
              <a :href="url">下载模板</a>
            </a-button>
            <a-button>
              <a class="a" :href="apiexp">铺位导出</a>
            </a-button>

            <a-button @click="inexcel"> 铺位导入 </a-button>
          </div>
          <a-button type="primary" @click.prevent="showDrawer">
            新增铺位
          </a-button>
          <a-button type="danger" @click.prevent="onDetele"> 删除 </a-button>
        </div>
        <div v-else>
          <a-icon
            @click="
              () => {
                this.Id = ''
                this.visible = false
              }
            "
            style="
              width: 30px;
              margin-right: 5px;
              cursor: pointer;
              font-size: 25px;
            "
            type="rollback"
          />
        </div>
      </div>
    </a-layout-content>

    <a-layout
      v-if="visible == false"
      style="padding: 24px; height: 100%; overflow: auto"
    >
      <a-layout-content
        style="
          background-color: #fff;
          min-width: 1395px;
          line-height: 60px;
          margin: 0;
          width: 100%;
        "
      >
        <a-form
          style="padding: 0 24px"
          class="ant-advanced-search-form"
          :form="fom"
          @submit="handleSearch"
        >
          <a-row class="flex-d-row" style="flex-wrap: wrap" :gutter="24">
            <a-col v-for="(i, index) in textlist" :key="index">
              <a-form-item style="margin-bottom: 0" :label="i">
                <a-select
                  v-model="params['cs.rent_state'].value"
                  v-if="i == '招商状态'"
                  placeholder="请选择招商状态"
                  style="width: 178px"
                >
                  <a-select-option value="0"> 启用 </a-select-option>
                  <a-select-option value="1"> 禁用 </a-select-option>
                </a-select>
                <a-select
                  v-else-if="i == '产权属性'"
                  style="width: 178px"
                  v-model="params['cs.property_type_id'].value"
                  placeholder="请选择产权"
                >
                  <a-select-option v-for="item in typelist" :key="item.id">
                    {{ item.name }}
                  </a-select-option>
                </a-select>
                <a-select
                  v-else-if="i == '铺位租赁状态'"
                  v-model="params['cs.state'].value"
                  style="width: 178px"
                  placeholder="请选择铺位租赁状态"
                >
                  <a-select-option value="0"> 未租 </a-select-option>
                  <a-select-option value="1"> 已租 </a-select-option>
                  <a-select-option value="2"> 预租 </a-select-option>
                </a-select>
                <a-input
                  v-else
                  style="width: 178px"
                  v-decorator="[
                    `${i}`,
                    {
                      rules: [],
                    },
                  ]"
                  :placeholder="'请输入' + i"
                />
              </a-form-item>
            </a-col>
            <a-col class="a-col-top">
              <a-button type="primary" html-type="submit"> 搜索 </a-button>
              <a-button :style="{ marginLeft: '8px' }" @click="handleReset">
                清空
              </a-button>
            </a-col>
          </a-row>
          <!-- <a-row>
            
          </a-row> -->
        </a-form>
      </a-layout-content>
      <a-layout-content
        style="background: #fff; padding: 24px; min-width: 1395px; margin: 0"
      >
        <!-- 表格 -->
        <div class="search-wrap">
          <div style="width: 100%">
            <a-table
              :row-selection="rowSelection"
              rowKey="id"
              :pagination="false"
              :columns="columns"
              :data-source="Storelist"
              :rowClassName="rowClassName"
            >
              <span slot="status" slot-scope="text, record">
                <a-switch
                  :checked="text == 0 ? true : false"
                  default-checked
                  @change="onchangestate(record)"
                />
              </span>
              <span slot="rentState" slot-scope="text">
                <a-badge
                  :status="text | rentStatusTypeFilter"
                  :text="text | rentStatusFilter"
                />
              </span>
              <span slot="locked" slot-scope="text, record">
                <a-tooltip v-if="record.locked == 1">
                  <template slot="title">
                    <div>
                      <span>{{ record.lockedByName }}</span>
                      <span style="margin-left: 15px">{{
                        record.lockedTime | time
                      }}</span>
                    </div>
                    <div>{{ record.lockedInfo }}</div>
                  </template>
                  <a-badge
                    :status="text | lockedTypeFilter"
                    :text="text | lockedFilter"
                  />
                </a-tooltip>
                <a-badge
                  v-else
                  :status="text | lockedTypeFilter"
                  :text="text | lockedFilter"
                />
              </span>
              <template slot="action" slot-scope="text, record">
                <a @click="onEdit(record)">编辑&nbsp;</a>
                <a-popconfirm
                  :title="
                    record.locked == 0 ? '你确定要锁定' : '你确定要解锁' + '吗?'
                  "
                  ok-text="是"
                  cancel-text="否"
                  @confirm="
                    getpropertylock(record.id, record.locked == 0 ? 1 : 0)
                  "
                  @cancel="cancel"
                >
                  <a v-show="record.rentState == 0" href="#">{{
                    record.locked == 0 ? '锁定' : '解锁'
                  }}</a>
                </a-popconfirm>
              </template>
              <!-- <a slot="gender" slot-scope="item">{{
                  item.gender == 1 ? "男" : "女"
                }}</a>
                <a-button
                  slot="state"
                  slot-scope="item"
                  :type="item.State == -1 ? 'danger' : 'primary'"
                ></a-button> -->
            </a-table>
            <a-pagination
              v-model="params.current"
              :page-size-options="pageSizeOptions"
              :page-size="params.pageSize"
              show-quick-jumper
              show-size-changer
              :default-current="2"
              :total="total"
              @change="onpagesize"
              @showSizeChange="onShowSizeChange"
            >
              <template slot="buildOptionText" slot-scope="props">
                <span v-if="props.value !== '50'">{{ props.value }}条/页</span>
                <span v-if="props.value === '50'">全部</span>
              </template>
            </a-pagination>
          </div>
        </div>
      </a-layout-content>
    </a-layout>
    <!-- 编辑页 -->
    <a-layout v-else style="padding: 24px; height: 100%; overflow: auto">
      <a-form-model
        ref="ruleForm"
        :model="form"
        :rules="rules"
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
      >
        <a-layout-content
          :style="{
            background: '#fff',
            margin: 0,
          }"
        >
          <div class="content-title">
            <span>基础信息</span>
          </div>
          <div style="flex-wrap: wrap" class="content-body flex-d-row">
            <a-form-model-item prop="projectId" label="项目名称">
              <a-select
                style="width: 100%"
                @change="properyChange"
                placeholder="请选择项目"
                disabled="disabled"
                v-model="form.projectId"
              >
                <a-select-option
                  v-for="item in projectlist"
                  :key="item.id"
                  :value="item.id"
                >
                  {{ item.shortName }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item prop="buildingId" label="楼栋">
              <a-select
                style="width: 100%"
                @change="buildChange"
                placeholder="请选择楼栋"
                v-model="form.buildingId"
              >
                <a-select-option v-for="item in buildlist" :key="item.id">
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item prop="floorId" label="楼层">
              <a-select
                v-model="form.floorId"
                style="width: 100%"
                @change="floorChange"
                placeholder="请选择楼层"
              >
                <a-select-option v-for="item in floorlist" :key="item.id">
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item ref="name" prop="name" label="铺位号">
              <a-input
                placeholder="请输入铺位号"
                @blur="
                  () => {
                    $refs.name.onFieldBlur()
                  }
                "
                v-model="form.name"
              />
            </a-form-model-item>
            <a-form-model-item prop="propertyTypeId" label="产权属性">
              <a-select
                style="width: 100%"
                :filter-option="filterOption"
                @change="typeChange"
                placeholder="请选择产权"
                v-model="form.propertyTypeId"
              >
                <a-select-option v-for="item in typelist" :key="item.id">
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item
              ref="totalArea"
              prop="totalArea"
              label="建筑面积"
            >
              <a-input
                placeholder="请输入建筑面积"
                @blur="
                  () => {
                    $refs.name.onFieldBlur()
                  }
                "
                v-model="form.totalArea"
              />
            </a-form-model-item>
            <a-form-model-item ref="useArea" prop="useArea" label="使用面积">
              <a-input
                placeholder="请输入使用面积"
                @blur="
                  () => {
                    $refs.name.onFieldBlur()
                  }
                "
                v-model="form.useArea"
              />
            </a-form-model-item>
            <a-form-model-item ref="rentArea" prop="rentArea" label="计租面积">
              <a-input
                placeholder="请输入计租面积"
                @blur="
                  () => {
                    $refs.name.onFieldBlur()
                  }
                "
                v-model="form.rentArea"
              />
            </a-form-model-item>
            <a-form-model-item prop="state" label="状态">
              <a-select
                style="width: 100%"
                show-search
                :filter-option="filterOption"
                v-model="form.state"
              >
                <a-select-option value="0">启用</a-select-option>
                <a-select-option value="1">禁用</a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item prop="rentState" label="租赁状态">
              <a-select
                style="width: 100%"
                show-search
                :filter-option="filterOption"
                v-model="form.rentState"
              >
                <a-select-option value="0">未租</a-select-option>
                <a-select-option value="1">已租</a-select-option>
                <a-select-option value="2">预租</a-select-option>
              </a-select>
            </a-form-model-item>
          </div>
        </a-layout-content>

        <!-- 价格 -->
        <a-layout-content style="background: #fff; margin: 24px 0">
          <div class="content-title">
            <span>价格信息</span>
          </div>
          <div style="flex-wrap: wrap" class="content-body flex-d-row">
            <!-- <a-form-model-item style="width:20%" label="单位">
              <a-input disabled="disabled" value="元/月" />
            </a-form-model-item>
            <a-form-model-item
              ref="monthLowestFee"
              prop="monthLowestFee"
              style="width:40%"
              label="标准价格"
            >
              <a-input
                @blur="
                  () => {
                    $refs.name.onFieldBlur();
                  }
                "
                placeholder="请输入标准价格"
                v-model="form.monthLowestFee"
              />
            </a-form-model-item>
            <a-form-model-item
              ref="monthStandardFee"
              prop="monthStandardFee"
              style="width:40%"
              label="最低价格"
            >
              <a-input
                @blur="
                  () => {
                    $refs.name.onFieldBlur();
                  }
                "
                placeholder="请输入最低价格"
                v-model="form.monthStandardFee"
              />
            </a-form-model-item> -->
            <a-form-model-item style="width: 20%" label="单位">
              <a-input disabled="disabled" value="元/m²·天" />
            </a-form-model-item>
            <a-form-model-item
              ref="dayLowestFee"
              prop="dayLowestFee"
              style="width: 40%"
              label="标准价格"
            >
              <a-input
                @blur="
                  () => {
                    $refs.name.onFieldBlur()
                  }
                "
                placeholder="请输入标准价格"
                v-model="form.dayLowestFee"
              />
            </a-form-model-item>
            <a-form-model-item
              ref="dayStandardFee"
              prop="dayStandardFee"
              style="width: 40%"
              label="最低价格"
            >
              <a-input
                @blur="
                  () => {
                    $refs.name.onFieldBlur()
                  }
                "
                placeholder="请输入最低价格"
                v-model="form.dayStandardFee"
              />
            </a-form-model-item>
          </div>
        </a-layout-content>
      </a-form-model>
      <!-- 图片 -->
      <a-layout-content
        style="background: #fff; margin-bottom: 24px; height: 217px"
      >
        <div class="content-title">
          <span>图片</span>
        </div>
        <div class="content-body">
          <div class="clearfix">
            <a-upload
              action=""
              list-type="picture-card"
              :file-list="form.images"
              @preview="handlePreview"
              :customRequest="handleChange"
              @change="handleChanges"
            >
              <div v-if="form.images.length < 5">
                <a-icon type="plus" />
                <div class="ant-upload-text">上传图片</div>
              </div>
            </a-upload>
            <a-modal
              :visible="previewVisible"
              :footer="null"
              @cancel="handleCancel"
            >
              <img alt="example" style="width: 100%" :src="previewImage" />
            </a-modal>
          </div>
        </div>
      </a-layout-content>
      <!-- 扩展信息 -->
      <a-layout-content
        :style="{
          background: '#fff',
          margin: 0,
        }"
      >
        <div class="content-title">
          <span>扩展信息</span>
          <a-icon
            v-if="extension == 0"
            @click="
              () => {
                this.extension = 1
              }
            "
            style="cursor: pointer"
            type="down"
          />
          <a-icon
            v-else
            @click="
              () => {
                this.extension = 0
              }
            "
            style="cursor: pointer"
            type="up"
          />
        </div>
        <div v-show="extension == 1">a</div>
      </a-layout-content>
    </a-layout>
    <a-modal
      title="导入铺位"
      :visible="upvisible"
      :confirm-loading="confirmLoading"
      @ok="clo"
      @cancel="clo"
    >
      <a-upload
        name="file"
        :multiple="true"
        :action="apiinp"
        :file-list="fileList"
        @change="handleChangeex"
      >
        <a-button> <a-icon type="upload" /> 导入表格</a-button>
      </a-upload>
    </a-modal>
    <a-layout-content
      v-if="visible == true"
      style="
        background-color: #fff;
        line-height: 60px;
        margin: 0;
        width: 100%;
        height: 70px;
        text-align: center;
      "
    >
      <div save-draw>
        <a-button html-type="submit" type="primary" @click="onSubmit">
          提交
        </a-button>
        <a-button @click="onClose" style="margin-left: 40px"> 取消 </a-button>
      </div>
    </a-layout-content>
  </a-layout>
</template>
<script>
const columns = [
  {
    title: '铺位号',
    dataIndex: 'name',
    scopedSlots: { customRender: 'name' },
  },
  {
    title: '楼栋',
    dataIndex: 'buildingName',
  },
  {
    title: '楼层',
    dataIndex: 'floorName',
  },
  {
    title: '建筑面积',
    dataIndex: 'totalArea',
    sorter: (a, b) => a.totalArea - b.totalArea,
  },
  {
    title: '使用面积',
    dataIndex: 'useArea',
    sorter: (a, b) => a.useArea - b.useArea,
  },
  {
    title: '招商状态',
    dataIndex: 'state',
    scopedSlots: { customRender: 'status' },
  },
  {
    title: '租赁状态',
    dataIndex: 'rentState',
    scopedSlots: { customRender: 'rentState' },
  },

  {
    title: '锁定状态',
    dataIndex: 'locked',
    scopedSlots: { customRender: 'locked' },
  },

  //   {
  //     title: "租赁状态",
  //     dataIndex: "rentState",
  //     scopedSlots: { customRender: "rentState" },
  //   },
  {
    title: '操作',
    dataIndex: 'id',
    scopedSlots: { customRender: 'action' },
  },
]
const lockedMap = {
  0: {
    status: 'success',
    text: '未锁定',
  },
  1: {
    status: 'error',
    text: '已锁定',
  },
}
const rentstatusMap = {
  0: {
    status: 'processing',
    text: '未租',
  },
  1: {
    status: 'success',
    text: '已租',
  },
  2: {
    status: 'sucess',
    text: '预租',
  },
}
function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = error => reject(error)
  })
}
const OSS = require('ali-oss')
let client
const api = process.env.VUE_APP_API_BASE_URL
import { nanoid } from 'nanoid'
import { imageMixin } from '../../../../mixins'
import http from '../../../../http'
export default {
  mixins: [imageMixin],
  data() {
    return {
      apiexp: api + 'property/store/excel/export',
      apiinp: api + 'property/store/excel/import',
      textlist: [
        '铺位号',
        '楼栋',
        '楼层',
        '产权属性',
        '招商状态',
        '铺位租赁状态',
      ],
      fom: this.$form.createForm(this, { name: 'advanced_search' }),
      pageSizeOptions: ['10', '20', '30', '40', '50'],
      previewVisible: false,
      previewImage: '',
      extension: 0,
      headers: {
        authorization: 'authorization-text',
      },
      logvisible: false,
      file: null,
      filetxt: {},
      // 行内
      columns,
      loading: false,
      visible: false,
      upvisible: false,
      labelCol: { span: 12 },
      wrapperCol: { span: 24 },
      // 要传参数
      res: {
        credit: {
          securityToken: '',
          accessKeySecret: '',
          accessKeyId: '',
        },
        region: '',
      },
      url: '',
      projectlist: [],
      buildlist: [],
      floorlist: [],
      Storelist: [],
      selectedRowKey: [],
      params: {
        current: 1,
        pageSize: 10,
        'cs.name': {
          value: '',
          op: '%like%',
        },
        'cb.name': {
          value: '',
          op: '=',
        },
        'cf.name': {
          value: '',
          op: '=',
        },
        'cs.property_type_id': {
          value: undefined,
          op: '=',
        },
        'cs.state': {
          value: undefined,
          op: '=',
        },
        'cs.rent_state': {
          value: undefined,
          op: '=',
        },
      },
      expand: false,
      total: 6,
      Id: '',
      fileList: [],
      form: {
        id: '',
        projectId: JSON.parse(localStorage.getItem('store-userlist'))
          .projectInfo.projectId,
        buildingId: '',
        name: '',
        totalArea: '',
        useArea: '',
        rentArea: '',
        dayLowestFee: '',
        dayStandardFee: '',
        monthLowestFee: '',
        monthStandardFee: '',
        floorId: '',
        propertyTypeId: '1426051906128113665',
        state: '0',
        rentState: '0',
        images: [],
      },
      rules: {
        projectId: [
          {
            required: true,
            message: '请选择项目',
            trigger: 'change',
          },
        ],
        buildingId: [
          {
            required: true,
            message: '请选择楼栋',
            trigger: 'change',
          },
        ],
        floorId: [
          {
            required: true,
            message: '请选择楼层',
            trigger: 'change',
          },
        ],
        name: [
          {
            required: true,
            message: '铺位号不能为空',
            trigger: 'blur',
          },
        ],
        propertyTypeId: [
          {
            required: true,
            message: '请选择产权属性',
            trigger: 'change',
          },
        ],
        totalArea: [
          {
            required: true,
            message: '建造面积不能为空',
            trigger: 'blur',
          },
        ],
        useArea: [
          {
            required: true,
            message: '使用面积不能为空',
            trigger: 'blur',
          },
        ],
        rentArea: [
          {
            required: true,
            message: '计租面积不能为空',
            trigger: 'blur',
          },
        ],
        state: [
          {
            required: true,
            message: '招商状态不能为空',
            trigger: 'change',
          },
        ],
        rentState: [
          {
            required: true,
            message: '租赁状态不能为空',
            trigger: 'change',
          },
        ],
        monthLowestFee: [
          {
            required: true,
            message: '标准价格不能为空',
            trigger: 'blur',
          },
        ],
        monthStandardFee: [
          {
            required: true,
            message: '最低价格不能为空',
            trigger: 'blur',
          },
        ],
        dayLowestFee: [
          {
            required: true,
            message: '标准价格不能为空',
            trigger: 'blur',
          },
        ],
        dayStandardFee: [
          {
            required: true,
            message: '最低价格不能为空',
            trigger: 'blur',
          },
        ],
      },
    }
  },
  filters: {
    time(time) {
      if (time == null) {
        return ' '
      } else {
        var times = time.split('T')
        return times[0] + ' ' + times[1]
      }
    },
    rentStatusFilter(type) {
      if (type == null) {
        return ' '
      } else {
        return rentstatusMap[type].text
      }
    },
    rentStatusTypeFilter(type) {
      if (type == null) {
        return ' '
      } else {
        return rentstatusMap[type].status || ''
      }
    },
    lockedFilter(type) {
      if (type == null) {
        return ' '
      } else {
        return lockedMap[type].text
      }
    },
    lockedTypeFilter(type) {
      if (type == null) {
        return ' '
      } else {
        return lockedMap[type].status || ''
      }
    },
  },
  //   created() {
  //     this.getParams();
  //   },
  methods: {
    handleSearch(e) {
      //   console.log(this.params);
      e.preventDefault()
      this.params.current = 1
      this.fom.validateFields((error, values) => {
        this.params['cs.name'].value = values.铺位号
        this.params['cb.name'].value = values.楼栋
        this.params['cf.name'].value = values.楼层
      })
      this.getStore()
    },

    handleReset() {
      this.fom.resetFields()
    },

    toggle() {
      this.expand = !this.expand
    },
    clo() {
      this.upvisible = false
      this.fileList = []
    },
    inexcel() {
      this.upvisible = true
    },
    outexcel() {
      let url
      url = client.signatureUrl('sccn/铺位导入模板.xlsx')
      //   console.log(url);
      this.url = url
    },
    onShowSizeChange(current, pageSize) {
      this.params.pageSize = pageSize
      this.timer()
    },
    handleChangeex(info) {
      if (info.file.status !== 'uploading') {
        // console.log(info.file.response.data.msg, info);
      }
      if (info.file.status === 'done') {
        this.$message.success(`${info.file.response.msg}`)
      } else if (info.file.status === 'error') {
        this.$message.error(`${info.file.name} 导入失败`)
      }
      let fileList = [...info.fileList]
      fileList = fileList.slice(-2)
      fileList = fileList.map(file => {
        if (file.response) {
          // Component will show file.url as link
          file.url = file.response.url
        }
        return file
      })
      setTimeout(() => {
        this.timer()
      }, 200)
      this.fileList = fileList
    },
    handleCancel() {
      this.previewVisible = false
    },
    async handlePreview(file) {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj)
      }
      this.previewImage = file.url || file.preview
      this.previewVisible = true
    },
    async getsts() {
      try {
        const res = await http.getsts()
        // console.log(res);
        this.res.credit.securityToken = res.data.data.credit.securityToken
        this.res.credit.accessKeySecret = res.data.data.credit.accessKeySecret
        this.res.credit.accessKeyId = res.data.data.credit.accessKeyId
        this.res.region = res.data.data.region
        client = new OSS({
          region: this.res.region,
          accessKeyId: this.res.credit.accessKeyId,
          accessKeySecret: this.res.credit.accessKeySecret,
          stsToken: this.res.credit.securityToken,
          // 填写Bucket名称。
          bucket: 'sccncloud2',
        })
      } catch (e) {
        console.error(e)
      }
    },
    handleChanges({ fileList }) {
      if (this.form.images.length > fileList.length) {
        this.form.images = fileList
      }
    },
    handleChange(file) {
      var data = file.file
      let name = file.file.name.split('.')
      this.putObject(
        client,
        nanoid(),
        name[name.length - 1],
        data,
        file.file.name
      )
    },
    async putObject(client, id, name, data, realName) {
      try {
        const result = await client.put(`sccn/${id}.${name}`, data)
        let url
        url = client.signatureUrl(result.name)
        var a = [result]
        a.forEach(item => {
          this.form.images.push({
            bucket: 'sccncloud2',
            name: item.name,
            realName: realName,
            uid: id,
            status: 'done',
            url: url,
          })
        })
      } catch (e) {
        console.log(e)
      }
    },
    //解锁

    async getpropertylock(id, locked) {
      try {
        const res = await http.getpropertylock(id, locked)
        const { success, msg } = res.data
        if (success) {
          this.timer()
          this.$message.success('操作成功')
        } else {
          this.$message.warning(msg)
        }
      } catch (ex) {
        this.$message.error(ex)
      }
    },
    showDrawer() {
      this.visible = true
      this.title = '新增'
      this.$store.commit('selectKeys')
      this.buildlist = []
      this.floorlist = []
      this.buildingid(
        JSON.parse(localStorage.getItem('store-userlist')).projectInfo.projectId
      )
    },
    onClose() {
      this.form = {
        id: '',
        name: '',
        totalArea: '',
        buildingId: '',
        useArea: '',
        rentArea: '',
        images: [],
        dayLowestFee: '',
        dayStandardFee: '',
        monthLowestFee: '',
        monthStandardFee: '',
        floorId: '',
        projectId: JSON.parse(localStorage.getItem('store-userlist'))
          .projectInfo.projectId,
        propertyTypeId: '1426051906128113665',
        state: '0',
        rentState: '0',
      }
      this.visible = false
      this.Id = ''
    },
    onSubmit() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          this.form.images = JSON.stringify(this.form.images)
          //   console.log("submit!", this.form);
          if (this.Id == '') {
            this.AddStore(this.form)
          } else if (this.Id != '') {
            this.putStore(this.form)
          }
        } else {
          this.$message.warning('必填参数不能为空')
          return false
        }
      })
    },
    onchangestate(e) {
      //   console.log(e);
      if (e.state == 1) {
        this.form.state = 0
      } else if (e.state == 0) {
        this.form.state = 1
      }
      this.Statestore(e.id, this.form.state)
    },
    onpagesize(e) {
      this.params.current = e
      this.timer()
    },

    // 状态
    async Statestore(id, state) {
      try {
        const res = await http.Statestore(id, state)
        const { success, msg } = res.data
        if (success) {
          this.$message.success(msg)
        } else {
          this.$message.warning(msg)
        }
        this.timer()
      } catch (ex) {
        console.log('error:' + ex)
        this.$message.error(ex)
      }
    },
    // 请求
    async getStore() {
      try {
        const res = await http.getStore(this.params)
        // console.log(res);
        const { success, data } = res.data
        if (success) {
          this.Storelist = data.stores.records
          this.total = data.stores.total
          //   console.log(data.stores.records);
        }
      } catch (ex) {
        console.log('ex:', ex)
      }
    },
    async getStoreId(id) {
      try {
        const res = await http.getStoreId(id)
        const { success, data } = res.data
        if (success) {
          this.project()
          this.form.name = data.store.name
          this.form.totalArea = data.store.totalArea
          this.form.useArea = data.store.useArea
          this.form.rentArea = data.store.rentArea
          this.form.dayLowestFee = data.store.dayLowestFee
          this.form.dayStandardFee = data.store.dayStandardFee
          this.form.monthLowestFee = data.store.monthLowestFee
          this.form.monthStandardFee = data.store.monthStandardFee
          this.form.floorId = data.store.floorId
          this.form.propertyTypeId = data.store.propertyTypeId
          this.form.state = data.store.state + ''
          this.form.rentState = data.store.rentState + ''
          this.form.projectId = data.store.projectId + ''
          this.form.buildingId = data.store.buildingId
          this.buildingid(data.store.projectId)
          this.floorid(data.store.buildingId)
          setTimeout(() => {
            if (data.store.images == null) {
              this.form.images = []
            } else {
              this.form.images = JSON.parse(data.store.images)
            }
            var a = []
            this.form.images.forEach(item => {
              let url
              url = client.signatureUrl(item.name)
              a.push({
                bucket: 'sccncloud2',
                name: item.name || item.realName,
                uid: nanoid(),
                status: 'done',
                url: url,
              })
            })
            this.form.images = a
          }, 200)
        }
      } catch (ex) {
        console.log('error:' + ex)
      }
    },
    // 新增
    async AddStore(form) {
      try {
        const res = await http.AddStore(form)
        const { success, msg } = res.data
        if (success) {
          this.$message.success(msg)
          this.onClose()
        } else {
          this.$message.warning(msg)
        }
        this.timer()
      } catch (ex) {
        console.log('error:' + ex)
        this.$message.error(ex)
      }
    },
    // 编辑
    onEdit(e) {
      //   console.log(e);
      this.Id = e.id
      this.form.id = e.id
      this.visible = true
      this.title = '编辑'
      this.getStoreId(e.id)
    },
    // 编辑
    async putStore(form) {
      try {
        const res = await http.putStore(form)
        const { success, msg } = res.data
        if (success) {
          this.$message.success(msg)
          this.onClose()
        } else {
          this.$message.warning(msg)
        }
        this.timer()
      } catch (ex) {
        console.log('error:' + ex)
        this.$message.error(ex)
      }
    },
    async exportstore() {
      try {
        const res = await http.exportstore()
        const { success, msg } = res.data
        if (success) {
          this.$message.success(msg)
        } else {
          this.$message.error(msg)
        }
        this.timer()
      } catch (ex) {
        console.log('error:' + ex)
        this.$message.error(ex)
      }
    },
    tostorechange() {
      localStorage.setItem(
        'selectedKeys',
        JSON.stringify(['/property/storeAdjust'])
      )
    },
    // 删除
    onDetele() {
      let that = this
      this.$confirm({
        title: '确认要删除该铺位么?',
        okText: '是',
        cancelText: '否',
        onOk() {
          setTimeout(() => {
            that.deleteStore()
          }, 100)
        },
        onCancel() {
          console.log()
        },
      })
    },
    async deleteStore() {
      try {
        const res = await http.deleteStore(this.selectedRowKey)
        const { success, msg } = res.data
        if (success) {
          this.$message.success(msg)
        } else {
          this.$message.error(msg)
        }
        this.timer()
      } catch (ex) {
        console.log('error:' + ex)
        this.$message.error(ex)
      }
    },
    //Change事件
    properyChange(value) {
      //   console.log(value);
      this.buildingid(value)
    },
    buildChange(value) {
      this.floorlist = []
      this.Storelist = []
      this.form.floorId = ''
      this.form.name = ''
      //   console.log(value);
      this.floorid(value)
    },
    floorChange() {
      this.Storelist = []
      this.form.name = ''
      //   console.log(value);
    },
    //下拉框
    async project() {
      try {
        const res = await http.project()
        const { success, data } = res.data
        if (success) {
          //   console.log(data);
          this.projectlist = data.projects
        }
      } catch (ex) {
        console.log('error:' + ex)
      }
    },
    async buildingid(projectId) {
      try {
        const res = await http.buildingid(projectId)
        const { success, data } = res.data
        if (success) {
          //   console.log(data);
          this.buildlist = data.buildings
        }
      } catch (ex) {
        console.log('error:' + ex)
      }
    },
    async floorid(buildingId) {
      try {
        const res = await http.floorid(buildingId)
        const { success, data } = res.data
        if (success) {
          //   console.log(data);
          this.floorlist = data.floors
        }
      } catch (ex) {
        console.log('error:' + ex)
      }
    },
    async type() {
      try {
        const res = await http.type()
        const { success, data } = res.data
        if (success) {
          //   console.log(data);
          this.typelist = data.propertyTypes
        }
      } catch (ex) {
        console.log('error:' + ex)
      }
    },
    handleClickRow(value) {
      return {
        on: {
          dblclick: () => {
            this.display = 'none'
            this.dis = 'block'
            // console.log(value);
            this.thing = value
          },
        },
      }
    },
    rowClassName(record, index) {
      let className = 'light'
      if (index % 2 === 1) className = 'dark'
      return className
    },
    timer() {
      return setTimeout(() => {
        this.getStore()
      }, 200)
    },
    onout() {
      this.$router.push({ name: 'login' })
    },
    beforeDestroy() {
      clearInterval(this.timer)
      this.timer = null
    },
    getParams() {
      console.log(this.$route.query.visible)
      // 如果是params传参，那就是this.$router.params.site
    },
  },

  mounted() {
    this.getStore()
    this.project()
    this.type()
    this.getsts()
    const users = JSON.parse(localStorage.getItem('store-userlist'))
    if (users == null) {
      this.logvisible = true
    }
    // console.log(this.$route.query.visible);
    if (this.$route.query.visible == 'true') {
      this.showDrawer()
    }
  },
  computed: {
    count() {
      return this.expand ? 7 : 5
    },
    rowSelection() {
      return {
        onChange: (selectedRowKey, selectedRows) => {
          this.selectedRowKey = selectedRowKey
          //   console.log("selectedRowKey:" + this.selectedRowKey);
          console.log('selectedRows:', selectedRows)
        },
      }
    },
  },
}
</script>
<style lang="less" scoped>
.search-wrap {
  width: 100%;
  /* background: #999; */
  //   box-shadow: 0 6px 12px 0 rgb(0 0 0 / 4%);
  border-radius: 3px;
  position: relative;
  min-height: 677px;
  height: 100%;
  padding: 10px 0 6px;
}
.price {
  position: absolute;
  right: 1px;
  bottom: -6px;
  width: 70px;
  height: 30px;
  line-height: 32px;
  text-align: center;
  background-color: #fafafa;
  border-left: 1px solid #d9d9d9;
}
.ant-btn {
  margin: 0px 8px;
}
.flex-d-row {
  display: flex;
  flex-direction: row;
}
.a-col-top {
  position: absolute;
  right: 0;
  top: 43px;
}
.flex-d-column {
  display: flex;
  flex-direction: column;
}
.ant-pagination {
  float: right;
  margin-top: 10px;
}
.ant-layout {
  height: 100%;
}
.Enter-top {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 24px;
  .Enter-top-right {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-right: 15px;
    .top-right-inpot {
      height: 32px;
      font-size: 13px;
      color: orange;
      border-radius: 22px;
      margin: 0 10px;
      border: 1px solid orange;
    }
  }
}
.updata > img {
  border-radius: 4px;
  margin-right: 5px;
  border: 1px dashed #d9d9d9;
  text-align: center;
  height: 102px;
  width: 102px;
}
.content-title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  padding: 0 24px;
  border-bottom: 1px solid #eaeaea;
}
.content-body {
  padding: 24px;
}
.content-body .price-formitem {
  width: 40%;
}
.ant-input {
  border-radius: 0;
  padding-left: 10px;
  color: #000;
}
.content-body > .ant-form-item {
  height: 90px;
}
.content-body {
  .ant-form-item {
    margin: 0;
    width: 20%;
    padding-right: 20px;
  }
}

::v-deep .ant-form-item-label {
  text-align: left;
}
::v-deep .ant-form-item label {
  margin-right: 16px;
  font-weight: 500;
}
::v-deep .ant-table .light {
  background-color: white;
}
::v-deep .ant-table .dark {
  background-color: #fafafa;
}
::v-deep .ant-input-affix-wrapper .ant-input:not(:last-child) {
  padding-right: 30px;
  padding-left: 10px;
}
.a {
  color: #5f5f5f;
}
.a:hover {
  color: #1890ff;
}
</style>
